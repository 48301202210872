import dashboardStatsService from '@/services/http/dashboard-stats.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getClaimscount({ rootState }, data) {
      const res = await dashboardStatsService.getClaimscount(data)
      return res
    },

    async getUserscount({ rootState }, data) {
      const res = await dashboardStatsService.getUserscount(data)
      return res
    },
    async getContestscount({ rootState }, data) {
      const res = await dashboardStatsService.getContestscount(data)
      return res
    },
    async getRequestscount({ rootState }, data) {
      const res = await dashboardStatsService.getRequestscount(data)
      return res
    },
  },
}
