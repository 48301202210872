import typeDemandesService from '@/services/http/type-demandes.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchTypeDemandes({ rootState }, data) {
      const res = await typeDemandesService.fetchTypeDemandes(data)
      return res
    },
    async updateTypeDemandes({ rootState }, data) {
      const res = await typeDemandesService.updateTypeDemandes(data)
      return res
    },

  },
}
