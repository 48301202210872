import { BaseService } from './base.service'

export class DashboardStats extends BaseService {
  constructor() {
    super()
  }

  getClaimscount() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/statistiques/complaints/count')
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  getUserscount() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/statistiques/users/count')
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  getContestscount(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('statistiques/posts/count', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  getRequestscount() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/statistiques/demandes/count')
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

}

export default new DashboardStats()