import { BaseService } from "./base.service";

export class TypeDemandeService extends BaseService {
  constructor() {
    super();
  }

  fetchTypeDemandes() {
    // console.log('fetchTypeDemandes');
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get("/type-demandes")
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  updateTypeDemandes(object) {
    const { id, data } = object;
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(`/type-demandes/${id}`, data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}

export default new TypeDemandeService();
